var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("page-title", {
        attrs: {
          heading: _vm.heading,
          subheading: _vm.subheading,
          icon: _vm.icon,
        },
      }),
      _c("tabs", {
        attrs: {
          tabs: _vm.tabs,
          currentTab: _vm.currentTab,
          "wrapper-class": "body-tabs shadow-tabs",
          "tab-class": "tab-item",
          "tab-active-class": "tab-item-active",
          "line-class": "tab-item-line",
        },
        on: { onClick: _vm.handleClick },
      }),
      _c("div", { staticClass: "content" }, [
        _vm.currentTab === "tab1"
          ? _c("div", [
              _vm._m(0),
              _c("div", { staticClass: "main-card mb-3 card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm._l(
                          [
                            "pe-7s-album",
                            "pe-7s-arc",
                            "pe-7s-back-2",
                            "pe-7s-bandaid",
                            "pe-7s-car",
                            "pe-7s-diamond",
                            "pe-7s-door-lock",
                            "pe-7s-eyedropper",
                            "pe-7s-female",
                            "pe-7s-gym",
                            "pe-7s-hammer",
                            "pe-7s-headphones",
                            "pe-7s-helm",
                            "pe-7s-hourglass",
                            "pe-7s-leaf",
                            "pe-7s-magic-wand",
                            "pe-7s-male",
                            "pe-7s-map-2",
                            "pe-7s-next-2",
                            "pe-7s-paint-bucket",
                            "pe-7s-pendrive",
                            "pe-7s-photo",
                            "pe-7s-piggy",
                            "pe-7s-plugin",
                            "pe-7s-refresh-2",
                            "pe-7s-rocket",
                            "pe-7s-settings",
                            "pe-7s-shield",
                            "pe-7s-smile",
                            "pe-7s-usb",
                            "pe-7s-vector",
                            "pe-7s-wine",
                            "pe-7s-cloud-upload",
                            "pe-7s-cash",
                            "pe-7s-close",
                            "pe-7s-bluetooth",
                            "pe-7s-cloud-download",
                            "pe-7s-way",
                            "pe-7s-close-circle",
                            "pe-7s-id",
                            "pe-7s-angle-up",
                            "pe-7s-wristwatch",
                            "pe-7s-angle-up-circle",
                            "pe-7s-world",
                            "pe-7s-angle-right",
                            "pe-7s-volume",
                            "pe-7s-angle-right-circle",
                            "pe-7s-users",
                            "pe-7s-angle-left",
                            "pe-7s-user-female",
                            "pe-7s-angle-left-circle",
                            "pe-7s-up-arrow",
                            "pe-7s-angle-down",
                            "pe-7s-switch",
                            "pe-7s-angle-down-circle",
                            "pe-7s-scissors",
                            "pe-7s-wallet",
                            "pe-7s-safe",
                            "pe-7s-volume2",
                            "pe-7s-volume1",
                            "pe-7s-voicemail",
                            "pe-7s-video",
                            "pe-7s-user",
                            "pe-7s-upload",
                            "pe-7s-unlock",
                            "pe-7s-umbrella",
                            "pe-7s-trash",
                            "pe-7s-tools",
                            "pe-7s-timer",
                            "pe-7s-ticket",
                            "pe-7s-target",
                            "pe-7s-sun",
                            "pe-7s-study",
                            "pe-7s-stopwatch",
                            "pe-7s-star",
                            "pe-7s-speaker",
                            "pe-7s-signal",
                            "pe-7s-shuffle",
                            "pe-7s-shopbag",
                            "pe-7s-share",
                            "pe-7s-server",
                            "pe-7s-search",
                            "pe-7s-film",
                            "pe-7s-science",
                            "pe-7s-disk",
                            "pe-7s-ribbon",
                            "pe-7s-repeat",
                            "pe-7s-refresh",
                            "pe-7s-add-user",
                            "pe-7s-refresh-cloud",
                            "pe-7s-paperclip",
                            "pe-7s-radio",
                            "pe-7s-note2",
                            "pe-7s-print",
                            "pe-7s-network",
                            "pe-7s-prev",
                            "pe-7s-mute",
                            "pe-7s-power",
                            "pe-7s-medal",
                            "pe-7s-portfolio",
                            "pe-7s-like2",
                            "pe-7s-plus",
                            "pe-7s-left-arrow",
                            "pe-7s-play",
                            "pe-7s-key",
                            "pe-7s-plane",
                            "pe-7s-joy",
                            "pe-7s-photo-gallery",
                            "pe-7s-pin",
                            "pe-7s-phone",
                            "pe-7s-plug",
                            "pe-7s-pen",
                            "pe-7s-right-arrow",
                            "pe-7s-paper-plane",
                            "pe-7s-delete-user",
                            "pe-7s-paint",
                            "pe-7s-bottom-arrow",
                            "pe-7s-notebook",
                            "pe-7s-note",
                            "pe-7s-next",
                            "pe-7s-news-paper",
                            "pe-7s-musiclist",
                            "pe-7s-music",
                            "pe-7s-mouse",
                            "pe-7s-more",
                            "pe-7s-moon",
                            "pe-7s-monitor",
                            "pe-7s-micro",
                            "pe-7s-menu",
                            "pe-7s-map",
                            "pe-7s-map-marker",
                            "pe-7s-mail",
                            "pe-7s-mail-open",
                            "pe-7s-mail-open-file",
                            "pe-7s-magnet",
                            "pe-7s-loop",
                            "pe-7s-look",
                            "pe-7s-lock",
                            "pe-7s-lintern",
                            "pe-7s-link",
                            "pe-7s-like",
                            "pe-7s-light",
                            "pe-7s-less",
                            "pe-7s-keypad",
                            "pe-7s-junk",
                            "pe-7s-info",
                            "pe-7s-home",
                            "pe-7s-help2",
                            "pe-7s-help1",
                            "pe-7s-graph3",
                            "pe-7s-graph2",
                            "pe-7s-graph1",
                            "pe-7s-graph",
                            "pe-7s-global",
                            "pe-7s-gleam",
                            "pe-7s-glasses",
                            "pe-7s-gift",
                            "pe-7s-folder",
                            "pe-7s-flag",
                            "pe-7s-filter",
                            "pe-7s-file",
                            "pe-7s-expand1",
                            "pe-7s-exapnd2",
                            "pe-7s-edit",
                            "pe-7s-drop",
                            "pe-7s-drawer",
                            "pe-7s-download",
                            "pe-7s-display2",
                            "pe-7s-display1",
                            "pe-7s-diskette",
                            "pe-7s-date",
                            "pe-7s-cup",
                            "pe-7s-culture",
                            "pe-7s-crop",
                            "pe-7s-credit",
                            "pe-7s-copy-file",
                            "pe-7s-config",
                            "pe-7s-compass",
                            "pe-7s-comment",
                            "pe-7s-coffee",
                            "pe-7s-cloud",
                            "pe-7s-clock",
                            "pe-7s-check",
                            "pe-7s-chat",
                            "pe-7s-cart",
                            "pe-7s-camera",
                            "pe-7s-call",
                            "pe-7s-calculator",
                            "pe-7s-browser",
                            "pe-7s-box2",
                            "pe-7s-box1",
                            "pe-7s-bookmarks",
                            "pe-7s-bicycle",
                            "pe-7s-bell",
                            "pe-7s-battery",
                            "pe-7s-ball",
                            "pe-7s-back",
                            "pe-7s-attention",
                            "pe-7s-anchor",
                            "pe-7s-albums",
                            "pe-7s-alarm",
                            "pe-7s-airplay",
                          ],
                          function (iconName) {
                            return [
                              _c(
                                "b-col",
                                { key: iconName, attrs: { md: "2" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-icon-wrapper" },
                                    [
                                      _c("i", { class: iconName }),
                                      _c("p", [_vm._v(_vm._s(iconName))]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.currentTab === "tab2"
          ? _c("div", [
              _c("div", { staticClass: "main-card mb-3 card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "font-icon-wrapper text-primary" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "coffee", size: "4x" },
                              }),
                              _c("p", [_vm._v('size="4x"')]),
                            ],
                            1
                          ),
                        ]),
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "font-icon-wrapper text-success" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "cog", size: "4x" },
                              }),
                              _c("p", [_vm._v("faCoffee")]),
                            ],
                            1
                          ),
                        ]),
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "font-icon-wrapper text-success" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "cog", size: "4x" },
                              }),
                              _c("p", [_vm._v("faCalendarAlt")]),
                            ],
                            1
                          ),
                        ]),
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "font-icon-wrapper text-danger" },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "spinner",
                                  spin: "",
                                  fixedWidth: "",
                                  size: "4x",
                                },
                              }),
                              _c("p", [_vm._v('spin fixedWidth size="4x"')]),
                            ],
                            1
                          ),
                        ]),
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "font-icon-wrapper text-info" },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "check-square",
                                  pulse: "",
                                  fixedWidth: "",
                                  size: "4x",
                                },
                              }),
                              _c("p", [_vm._v('pulse fixedWidth size="4x"')]),
                            ],
                            1
                          ),
                        ]),
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "font-icon-wrapper text-warning" },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "archive",
                                  flip: "both",
                                  size: "4x",
                                },
                              }),
                              _c("p", [_vm._v('flip="both" size="4x"')]),
                            ],
                            1
                          ),
                        ]),
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "font-icon-wrapper text-dark" },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "angry",
                                  size: "4x",
                                  transform: "left-1 rotate-15",
                                },
                              }),
                              _c("p", [
                                _vm._v(
                                  'size="4x" transform="left-1 rotate-15"'
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.currentTab === "tab3"
          ? _c("div", [
              _vm._m(1),
              _c("div", { staticClass: "main-card mb-3 card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm._l(
                          [
                            "lnr-apartment",
                            "lnr-pencil",
                            "lnr-magic-wand",
                            "lnr-drop",
                            "lnr-lighter",
                            "lnr-poop",
                            "lnr-sun",
                            "lnr-moon",
                            "lnr-cloud",
                            "lnr-cloud-upload",
                            "lnr-cloud-download",
                            "lnr-cloud-sync",
                            "lnr-cloud-check",
                            "lnr-database",
                            "lnr-lock",
                            "lnr-cog",
                            "lnr-trash",
                            "lnr-dice",
                            "lnr-heart",
                            "lnr-star",
                            "lnr-star-half",
                            "lnr-star-empty",
                            "lnr-flag",
                            "lnr-envelope",
                            "lnr-paperclip",
                            "lnr-inbox",
                            "lnr-eye",
                            "lnr-printer",
                            "lnr-file-empty",
                            "lnr-file-add",
                            "lnr-enter",
                            "lnr-exit",
                            "lnr-graduation-hat",
                            "lnr-license",
                            "lnr-music-note",
                            "lnr-film-play",
                            "lnr-camera-video",
                            "lnr-camera",
                            "lnr-picture",
                            "lnr-book",
                            "lnr-bookmark",
                            "lnr-user",
                            "lnr-users",
                            "lnr-shirt",
                            "lnr-store",
                            "lnr-cart",
                            "lnr-tag",
                            "lnr-phone-handset",
                            "lnr-phone",
                            "lnr-pushpin",
                            "lnr-map-marker",
                            "lnr-map",
                            "lnr-location",
                            "lnr-calendar-full",
                            "lnr-keyboard",
                            "lnr-spell-check",
                            "lnr-screen",
                            "lnr-smartphone",
                            "lnr-tablet",
                            "lnr-laptop",
                            "lnr-laptop-phone",
                            "lnr-power-switch",
                            "lnr-bubble",
                            "lnr-heart-pulse",
                            "lnr-construction",
                            "lnr-pie-chart",
                            "lnr-chart-bars",
                            "lnr-gift",
                            "lnr-diamond",
                            "lnr-linearicons",
                            "lnr-dinner",
                            "lnr-coffee-cup",
                            "lnr-leaf",
                            "lnr-paw",
                            "lnr-rocket",
                            "lnr-briefcase",
                            "lnr-bus",
                            "lnr-car",
                            "lnr-train",
                            "lnr-bicycle",
                            "lnr-wheelchair",
                            "lnr-select",
                            "lnr-earth",
                            "lnr-smile",
                            "lnr-sad",
                            "lnr-neutral",
                            "lnr-mustache",
                            "lnr-alarm",
                            "lnr-bullhorn",
                            "lnr-volume-high",
                            "lnr-volume-medium",
                            "lnr-volume-low",
                            "lnr-volume",
                            "lnr-mic",
                            "lnr-hourglass",
                            "lnr-undo",
                            "lnr-redo",
                            "lnr-sync",
                            "lnr-history",
                            "lnr-clock",
                            "lnr-download",
                            "lnr-upload",
                            "lnr-enter-down",
                            "lnr-exit-up",
                            "lnr-bug",
                            "lnr-code",
                            "lnr-link",
                            "lnr-unlink",
                            "lnr-thumbs-up",
                            "lnr-thumbs-down",
                            "lnr-magnifier",
                            "lnr-cross",
                            "lnr-menu",
                            "lnr-list",
                            "lnr-chevron-up",
                            "lnr-chevron-down",
                            "lnr-chevron-left",
                            "lnr-chevron-right",
                            "lnr-arrow-up",
                            "lnr-arrow-down",
                            "lnr-arrow-left",
                            "lnr-arrow-right",
                            "lnr-move",
                            "lnr-warning",
                            "lnr-question-circle",
                            "lnr-menu-circle",
                            "lnr-checkmark-circle",
                            "lnr-cross-circle",
                            "lnr-plus-circle",
                            "lnr-circle-minus",
                            "lnr-arrow-up-circle",
                            "lnr-arrow-down-circle",
                            "lnr-arrow-left-circle",
                            "lnr-arrow-right-circle",
                            "lnr-chevron-up-circle",
                            "lnr-chevron-down-circle",
                            "lnr-chevron-left-circle",
                            "lnr-chevron-right-circle",
                            "lnr-crop",
                            "lnr-frame-expand",
                            "lnr-frame-contract",
                            "lnr-layers",
                            "lnr-funnel",
                            "lnr-text-format",
                            "lnr-text-format-remove",
                            "lnr-text-size",
                            "lnr-bold",
                            "lnr-italic",
                            "lnr-underline",
                            "lnr-strikethrough",
                            "lnr-highlight",
                            "lnr-text-align-left",
                            "lnr-text-align-center",
                            "lnr-text-align-right",
                            "lnr-text-align-justify",
                            "lnr-line-spacing",
                            "lnr-indent-increase",
                            "lnr-indent-decrease",
                            "lnr-pilcrow",
                            "lnr-direction-ltr",
                            "lnr-direction-rtl",
                            "lnr-page-break",
                            "lnr-sort-alpha-asc",
                            "lnr-sort-amount-asc",
                            "lnr-hand",
                            "lnr-pointer-up",
                            "lnr-pointer-right",
                            "lnr-pointer-down",
                            "lnr-pointer-left",
                          ],
                          function (iconName) {
                            return [
                              _c(
                                "b-col",
                                { key: iconName, attrs: { md: "2" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-icon-wrapper" },
                                    [
                                      _c("i", { class: iconName }),
                                      _c("p", [_vm._v(_vm._s(iconName))]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.currentTab === "tab4"
          ? _c("div", [
              _c("div", { staticClass: "main-card mb-3 card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("div", { staticClass: "card-title" }, [
                      _vm._v("Examples"),
                    ]),
                    _c(
                      "b-row",
                      [
                        _vm._l(
                          [
                            "UG",
                            "US",
                            "UY",
                            "UZ",
                            "VA",
                            "VC",
                            "VE",
                            "VG",
                            "VI",
                          ],
                          function (iconName) {
                            return [
                              _c(
                                "b-col",
                                { key: iconName, attrs: { md: "2" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-icon-wrapper" },
                                    [
                                      _c("country-flag", {
                                        attrs: {
                                          country: iconName,
                                          size: "big",
                                        },
                                      }),
                                      _c("p", [_vm._v(_vm._s(iconName))]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "main-card mb-3 card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("div", { staticClass: "card-title" }, [
                      _vm._v("Available Country Codes for Flags"),
                    ]),
                    _c(
                      "b-row",
                      [
                        _vm._l(
                          [
                            "AD",
                            "AE",
                            "AF",
                            "AG",
                            "AI",
                            "AL",
                            "AM",
                            "AO",
                            "AR",
                            "AS",
                            "AT",
                            "AU",
                            "AW",
                            "AX",
                            "AZ",
                            "BA",
                            "BB",
                            "BD",
                            "BE",
                            "BF",
                            "BG",
                            "BH",
                            "BI",
                            "BJ",
                            "BL",
                            "BM",
                            "BN",
                            "BO",
                            "BR",
                            "BS",
                            "BT",
                            "BV",
                            "BW",
                            "BY",
                            "BZ",
                            "CA",
                            "CC",
                            "CD",
                            "CF",
                            "CG",
                            "CH",
                            "CI",
                            "CK",
                            "CL",
                            "CM",
                            "CN",
                            "CO",
                            "CR",
                            "CU",
                            "CV",
                            "CW",
                            "CX",
                            "CY",
                            "CZ",
                            "DE",
                            "DJ",
                            "DK",
                            "DM",
                            "DO",
                            "DZ",
                            "EC",
                            "EE",
                            "EG",
                            "ER",
                            "ES",
                            "ET",
                            "EU",
                            "FI",
                            "FJ",
                            "FK",
                            "FM",
                            "FO",
                            "FR",
                            "GA",
                            "GB",
                            "GD",
                            "GE",
                            "GF",
                            "GG",
                            "GH",
                            "GI",
                            "GL",
                            "GM",
                            "GN",
                            "GP",
                            "GQ",
                            "GR",
                            "GS",
                            "GT",
                            "GU",
                            "GW",
                            "GY",
                            "HK",
                            "HM",
                            "HN",
                            "HR",
                            "HT",
                            "HU",
                            "ID",
                            "IE",
                            "IL",
                            "IM",
                            "IN",
                            "IO",
                            "IQ",
                            "IR",
                            "IS",
                            "IT",
                            "JE",
                            "JM",
                            "JO",
                            "JP",
                            "KE",
                            "KG",
                            "KH",
                            "KI",
                            "KM",
                            "KN",
                            "KP",
                            "KR",
                            "KW",
                            "KY",
                            "KZ",
                            "LA",
                            "LB",
                            "LC",
                            "LI",
                            "LK",
                            "LR",
                            "LS",
                            "LT",
                            "LU",
                            "LV",
                            "LY",
                            "MA",
                            "MC",
                            "MD",
                            "ME",
                            "MF",
                            "MG",
                            "MH",
                            "MK",
                            "ML",
                            "MM",
                            "MN",
                            "MO",
                            "MP",
                            "MQ",
                            "MR",
                            "MS",
                            "MT",
                            "MU",
                            "MV",
                            "MW",
                            "MX",
                            "MY",
                            "MZ",
                            "NA",
                            "NC",
                            "NE",
                            "NF",
                            "NG",
                            "NI",
                            "NL",
                            "NO",
                            "NP",
                            "NR",
                            "NU",
                            "NZ",
                            "OM",
                            "PA",
                            "PE",
                            "PF",
                            "PG",
                            "PH",
                            "PK",
                            "PL",
                            "PM",
                            "PN",
                            "PR",
                            "PS",
                            "PT",
                            "PW",
                            "PY",
                            "QA",
                            "RE",
                            "RO",
                            "RS",
                            "RU",
                            "RW",
                            "SA",
                            "SB",
                            "SC",
                            "SD",
                            "SE",
                            "SG",
                            "SH",
                            "SI",
                            "SJ",
                            "SK",
                            "SL",
                            "SM",
                            "SN",
                            "SO",
                            "SR",
                            "SS",
                            "ST",
                            "SV",
                            "SX",
                            "SY",
                            "SZ",
                            "TC",
                            "TD",
                            "TF",
                            "TG",
                            "TH",
                            "TJ",
                            "TK",
                            "TL",
                            "TM",
                            "TN",
                            "TO",
                            "TR",
                            "TT",
                            "TV",
                            "TW",
                            "TZ",
                            "UA",
                            "UG",
                            "UM",
                            "US",
                            "UY",
                            "UZ",
                            "VA",
                            "VC",
                            "VE",
                            "VG",
                            "VI",
                            "VN",
                            "VU",
                            "WF",
                            "WS",
                            "XK",
                            "YE",
                            "YT",
                            "ZA",
                            "ZM",
                            "ZW",
                          ],
                          function (iconName) {
                            return [
                              _c(
                                "b-col",
                                { key: iconName, attrs: { md: "2" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-icon-wrapper" },
                                    [_c("p", [_vm._v(_vm._s(iconName))])]
                                  ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-card mb-3 card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-title" }, [_vm._v("Gradient Icons")]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-filter icon-gradient bg-warm-flame" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-help1 icon-gradient bg-night-fade" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-moon icon-gradient bg-sunny-morning" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-plane icon-gradient bg-tempting-azure",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-box2 icon-gradient bg-amy-crisp" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-lock icon-gradient bg-malibu-beach" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-monitor icon-gradient bg-mean-fruit" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-mouse icon-gradient bg-heavy-rain" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-paint icon-gradient bg-arielle-smile",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-menu icon-gradient bg-ripe-malin" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-wristwatch icon-gradient bg-deep-blue",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-volume2 icon-gradient bg-happy-itmeo",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-video icon-gradient bg-happy-fisher" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-wallet icon-gradient bg-plum-plate" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-paint-bucket icon-gradient bg-grow-early",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-diamond icon-gradient bg-strong-bliss",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-magic-wand icon-gradient bg-mixed-hopes",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "pe-7s-arc icon-gradient bg-premium-dark" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "pe-7s-hourglass icon-gradient bg-love-kiss",
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-card mb-3 card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-title" }, [_vm._v("Gradient Icons")]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-star icon-gradient bg-warm-flame" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-database icon-gradient bg-night-fade" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "lnr-apartment icon-gradient bg-sunny-morning",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-cog icon-gradient bg-tempting-azure" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-trash icon-gradient bg-amy-crisp" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-lock icon-gradient bg-malibu-beach" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-screen icon-gradient bg-mean-fruit" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "lnr-laptop-phone icon-gradient bg-heavy-rain",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "lnr-calendar-full icon-gradient bg-arielle-smile",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-user icon-gradient bg-ripe-malin" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-film-play icon-gradient bg-deep-blue" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "lnr-graduation-hat icon-gradient bg-happy-itmeo",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-eye icon-gradient bg-happy-fisher" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-hand icon-gradient bg-plum-plate" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "lnr-camera-video icon-gradient bg-grow-early",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-diamond icon-gradient bg-strong-bliss" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", {
            staticClass: "lnr-magic-wand icon-gradient bg-mixed-hopes",
          }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-heart icon-gradient bg-premium-dark" }),
        ]),
        _c("div", { staticClass: "font-icon-wrapper font-icon-lg" }, [
          _c("i", { staticClass: "lnr-hourglass icon-gradient bg-love-kiss" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }